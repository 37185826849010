import React, {Fragment, useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Form, Header, Icon, Loader, Menu} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Link} from "react-router-dom";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import axios from "axios";
import TeacherForm from "./TeacherForm";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../Main";
import SearchTableData from "../Utilities/SearchTableData";
import ReactExport from 'react-data-export';
import PaginationFront from "../Utilities/PaginationFront";
import moment from "moment";

const reducer = (state, action)=>{
    switch (action.type) {
        case 'teachers':
            return {...state, teachers: action.payload, loader:  false };
        case 'teachersData':
            return {...state, teachersData: action.payload, loader: false};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'organizer':
            return {...state, organizer: action.payload};
        case 'organizers':
            return {...state, organizers: action.payload};
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'data':
            return {...state, data: action.payload};
        case 'results':
            return {...state, results: action.payload};
        case 'postsPerPage':
            return {...state, postsPerPage: action.payload};
        case 'currentPosts':
            return {...state, currentPosts: action.payload};
        default:
            return state;
    }
};
const Teachers = () => {
    const [activePage, setActivePage] = useState(1);
    const [state, dispatch] = useReducer(reducer,{
        teachers:[],
        teachersData: [],
        openTable: false,
        loader: false,
        organizer: '',
        organizers: [],
        data:[],
        results:[],
        postsPerPage: 20,
        currentPosts: [],
    } );
    const {activeRole} = useContext(UserRoleContext);
    const { ExcelFile } = ReactExport;
    const { ExcelSheet } = ReactExport.ExcelFile;
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);

    const indexOfLastPost = activePage * state.postsPerPage;
    const indexOfFirstPost = indexOfLastPost - state.postsPerPage;
    const currentPosts = getDataToRender().slice(indexOfFirstPost, indexOfLastPost);

    const multiDataSet = [
        {
            columns: [
                { title: 'JMBG', width: { wpx: 150 } },
                { title: 'Ime', width: { wpx: 200 } },
                { title: 'Prezime', width: { wpx: 200 } },
                { title: 'Matično zaposlenje', width: { wpx: 200 } },
                { title: 'Broj časova sedmično', width: { wpx: 200 } },
            ],
            data: state.teachersData,
        }
    ];


    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function getDataToRender() {
        if (state.results.length !== 0) {
            return state.results;
        }
        return state.data;
    }

    const handlePaginationChange = (e, { activePage }) => {
        setActivePage(activePage);
    };

    function getResults(results) {
        dispatch({type: 'results', payload: results});
        setActivePage(1);
        handlePaginationChange({}, { activePage });
    }

    useEffect(() => {
        activeRole === "ROLE_ORGANIZER" ? getTeachers() : getOrganizers();
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });
    },[activeRole]);

    useEffect(() => {
        dispatch({type: 'organizer', payload: parseInt(localStorage.getItem('organizerOption')) ? parseInt(localStorage.getItem('organizerOption')) : ''});
        if ((activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && state.organizer) getTeachersForAdmin(parseInt(localStorage.getItem('organizerOption')));

    },[state.organizer]);

    function getTeachers(){
        axios.get('/teacher/by-organizer',).then(
            (res) => {
                // let teachers = res.data;
                let teachers = res.data.map(el => ({
                    ...el,
                    personJMBG: el.person.jmbg,
                    personFirst: el.person.firstName,
                    personLast: el.person.lastName
                }))
                dispatch({type: 'teachers', payload: teachers});
                dispatch({type: 'data', payload: teachers});
                let teachersData = res.data.map(el => ([
                    { value: el.person.jmbg },
                    { value: el.person.firstName },
                    { value: el.person.lastName },
                    { value: el.originPlaceOfEmployment ? el.originPlaceOfEmployment : ''},
                    { value: el.numberOfHoursInOriginPlaceOfEmployment ? el.numberOfHoursInOriginPlaceOfEmployment : '' },
                ]));
                dispatch({type: 'teachersData', payload: teachersData});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
        if(name === 'organizer' && value){
            localStorage.setItem('organizerOption', value);
            dispatch({type: 'openTable', payload: true});
            getTeachersForAdmin(value)
        }
    }

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function setCurrentIdOrganizer(value){
        dispatch({type: 'organizer', payload: value});
    }

    function getTeachersForAdmin(id){
        setCurrentId(id);
        setCurrentIdOrganizer(id);
        dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.get(`/teacher/by-organizer-id/${id}`,).then(
            (res) => {
                // let teachers = res.data;
                let teachers = res.data.map(el => ({
                    ...el,
                    personJMBG: el.person.jmbg,
                    personFirst: el.person.firstName,
                    personLast: el.person.lastName
                }))
                dispatch({type: 'teachers', payload: teachers});
                dispatch({type: 'data', payload: teachers});
                let teachersData = res.data.map(el => ([
                    { value: el.person.jmbg },
                    { value: el.person.firstName },
                    { value: el.person.lastName },
                    { value: el.originPlaceOfEmployment ? el.originPlaceOfEmployment : ''},
                    { value: el.numberOfHoursInOriginPlaceOfEmployment ? el.numberOfHoursInOriginPlaceOfEmployment : '' },
                ]));
                dispatch({type: 'teachersData', payload: teachersData});
                dispatch({type: 'loader', payload: false});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getOrganizers(){
        dispatch({type: 'loader', payload: true});
        axios.get('/organizer/all',).then(
            (res) => {
                let organizers = res.data.map((el)=>({...el, key: el.id, text:el.name, value: el.id}));
                dispatch({type: 'organizers', payload: organizers});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }


    function deleteTeacherById(id){
        setCurrentId(id);
        axios.delete(`/teacher-organizer/delete-by-teacher-and-organizer/${id}`,).then(
            (res) => {
                getTeachers();
                //activeRole === "ROLE_ORGANIZER" && getTeachers();
                // activeRole === "ROLE_ADMIN" && getTeachersForAdmin(state.organizer);
                toast('Uspješno ste izbrisali nastavnika/icu!');
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err);
            if (err.response.data.message === 'Could not delete teacher because of existing committees whose member is this teacher!') {
                toast('Brisanje nije moguće. Nastavnik/ica je dodan/a kao član komisije!');
            }
            else if (err.response.data.message === 'Could not delete teacher because he/she is added as educational group head!') {
                toast('Brisanje nije moguće. Nastavnik/ica je dodan/a kao voditelj obrazovne grupe!');
            }
            else {
                toast('Došlo je do greške! Brisanje nastavnika/ice nije uspjelo.');
            }
            dispatch({type: 'currentId', payload: null});
        });
    }

    function deleteTeacherForAdmin(id){
        setCurrentId(id);
        // setCurrentIdOrganizer(id);
        // dispatch({type: 'loader', payload: true});
        dispatch({type: 'openTable', payload: true});
        axios.delete(`/teacher-organizer/delete-by-teacher-and-organizer/${id}/${state.organizer}`,).then(
            (res) => {
                getTeachersForAdmin(state.organizer);
                toast('Uspješno ste izbrisali nastavnika/icu!');
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err);
            if (err.response.data.message === 'Could not delete teacher because of existing committees whose member is this teacher!') {
                toast('Brisanje nije moguće. Nastavnik/ica je dodan/a kao član komisije!');
            }
            else if (err.response.data.message === 'Could not delete teacher because he/she is added as educational group head!') {
                toast('Brisanje nije moguće. Nastavnik/ica je dodan/a kao voditelj obrazovne grupe!');
            } else {
                toast('Došlo je do greške! Brisanje nastavnika/ice nije uspjelo.');
            }
            dispatch({type: 'currentId', payload: null});
        });
    }

    return (
        <div>
            <Segment.Group >

                <Segment className={'customPadding'} clearing color={"teal"}>

                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Spisak nastavnika </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            {(!showEditForm && activeRole !== "ROLE_MINISTRY" && activeRole !== "ROLE_ADMIN") && <Menu.Item>
                                <Button icon labelPosition='left' size="mini" basic color="black" onClick={toggleAddForm}>
                                    <Icon name='add' color={"teal"}/>
                                    Kreiraj nastavnika
                                </Button>
                            </Menu.Item>}
                            {((activeRole === "ROLE_ORGANIZER") || (state.organizer && (activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"))) && <Menu.Item>
                                <ExcelFile filename="Spisak nastavnika" element={<Button icon labelPosition='left' size="tiny" basic color="black">
                                    <Icon name='file excel outline' color={"green"}/>
                                    Preuzmi spisak
                                </Button>}>
                                    <ExcelSheet dataSet={multiDataSet} name="Nastavnici" />
                                </ExcelFile>
                            </Menu.Item>}
                            <Menu.Item>
                                <span>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                            {/*<Menu.Item>*/}
                            {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                            {/*        <Icon name='file pdf outline' color={"red"} />*/}
                            {/*        Preuzmi spisak*/}
                            {/*    </Button>*/}
                            {/*</Menu.Item>*/}
                            {/*<Menu.Item>*/}
                            {/*    <Button icon labelPosition='left' size="mini" basic color="black">*/}
                            {/*        <Icon name='file excel outline' color={"green"}/>*/}
                            {/*        Preuzmi spisak*/}
                            {/*    </Button>*/}
                            {/*</Menu.Item>*/}
                        </Menu.Menu>

                    </Menu>

                </Segment>

                {showAddForm ?
                <Segment>
                    <TeacherForm add toggleEditForm={toggleAddForm} getTeachers={getTeachers} getTeachersForAdmin={getTeachersForAdmin} setCurrentIdOrganizer={setCurrentIdOrganizer}/>
                </Segment> :

                    <Fragment>

                {(activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY") && <Segment><Form size={'small'}>
                    <Form.Group widths="3">
                        <Form.Field>
                            <label>Organizator</label>
                            <Form.Dropdown
                                placeholder="Odaberite organizatora"
                                value={state.organizer}
                                onChange={handleInputChange}
                                name="organizer"
                                options={state.organizers}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                    </Form.Group>
                </Form></Segment>}

                {((activeRole === "ROLE_ADMIN" && state.openTable) || (activeRole === "ROLE_MINISTRY" && state.openTable) || activeRole === "ROLE_ORGANIZER") &&
                <Segment>
                    {state.loader ?
                        <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                        : currentPosts.length ?<Table celled size={"small"}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan={9} >
                                        <div style={{ paddingLeft: '0px' }}>
                                            <SearchTableData data={state.data} getResults={getResults} filterItem={['originPlaceOfEmployment', 'numberOfHoursInOriginPlaceOfEmployment', 'personJMBG', 'personLast', 'personFirst']} />
                                        </div>
                                    </Table.HeaderCell>
                                </Table.Row>
                                <Table.Row>

                                    <Table.HeaderCell width={1} >R. br.</Table.HeaderCell>
                                    <Table.HeaderCell>JMBG</Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Header size={"small"}>
                                        Ime i prezime
                                        <span><Icon/></span>
                                        <Header.Subheader>
                                            <i>Nastavnik/ica</i>
                                        </Header.Subheader>
                                    </Header>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Header size={"small"}>
                                        Matično zaposlenje
                                        <span><Icon/></span>
                                        <Header.Subheader>
                                            <i>Institucija</i>
                                        </Header.Subheader>
                                    </Header>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell>
                                        <Header size={"small"}>
                                        Broj časova sedmično
                                        <span><Icon/></span>
                                        <Header.Subheader>
                                            <i>Matično zaposlenje</i>
                                        </Header.Subheader>
                                    </Header>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {
                                    currentPosts.map((el, index) =>
                                    // state.teachers.map((el, index)=>
                                        (<Table.Row key={el.id}>
                                            <Table.Cell width={1}>{(index + 1) + ((activePage - 1) * state.postsPerPage)}.</Table.Cell>
                                            <Table.Cell>{el.person.jmbg}</Table.Cell>
                                            <Table.Cell>{el.person.firstName} {el.person.lastName}</Table.Cell>
                                            <Table.Cell>{el.originPlaceOfEmployment}</Table.Cell>
                                            <Table.Cell>{el.numberOfHoursInOriginPlaceOfEmployment}</Table.Cell>
                                            <Table.Cell width={1} verticalAlign="middle" textAlign="center">
                                                <Link to='/nastavnici/detalji' state={{ name: el.person.firstName +" "+ el.person.lastName, id: el.id, teacher: el, personId: el.person.id, organizer: state.organizer}}>
                                                    <Popup size={"small"} content='O nastavniku' trigger={ <Button icon size={"mini"} basic color={"grey"}>
                                                        <Icon name='user' />
                                                    </Button>} />
                                                </Link>

                                                {/*<Link to='/nastavnici/angažmani' state={{ name: el.person.firstName +" "+ el.person.lastName, id: el.id, teacher: el, personId: el.person.id}}>*/}
                                                {/*    <Popup size={"small"} content='Angažmani' trigger={ <Button icon size={"mini"} basic color={"blue"}>*/}
                                                {/*        <Icon name='list' />*/}
                                                {/*    </Button>} />*/}
                                                {/*</Link>*/}

                                                {activeRole !== "ROLE_MINISTRY" &&<Popup size={"small"} content='Obriši nastavnika' trigger={
                                                    <Button icon size={"mini"} basic color={"grey"} disabled={state.currentId && el.id !== state.currentId } onClick={() => {activeRole === "ROLE_ORGANIZER" ? deleteTeacherById(el.id) : deleteTeacherForAdmin(el.id)}} >
                                                        <Icon name='trash alternate outline' color={"red"} />
                                                    </Button>}
                                                />}
                                            </Table.Cell>
                                        </Table.Row>)
                                    )
                                }


                            </Table.Body>

                            <Table.Footer>
                                {/*<Table.Row>*/}
                                {/*    <Table.HeaderCell colSpan='11' textAlign='center'>*/}
                                {/*        <Menu  pagination>*/}
                                {/*            <Menu.Item as='a' icon>*/}
                                {/*                <Icon name='chevron left' />*/}
                                {/*            </Menu.Item>*/}
                                {/*            <Menu.Item as='a'>1</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>2</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>3</Menu.Item>*/}
                                {/*            <Menu.Item as='a'>4</Menu.Item>*/}
                                {/*            <Menu.Item as='a' icon>*/}
                                {/*                <Icon name='chevron right' />*/}
                                {/*            </Menu.Item>*/}
                                {/*        </Menu>*/}
                                {/*    </Table.HeaderCell>*/}
                                {/*</Table.Row>*/}
                            </Table.Footer>
                        </Table>: <div>Nema unesenih podataka o nastavnicima.</div>}
                    {currentPosts.length >0 && <PaginationFront postsPerPage={state.postsPerPage} totalPosts={getDataToRender().length} paginate={handlePaginationChange} activePage={activePage} />}
                </Segment>}</Fragment>}
            </Segment.Group>
            <ToastContainer  />
        </div>
    );
};

export default Teachers;
