import React from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {Checkbox, Header, Label} from "semantic-ui-react";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import moment from "moment";

const NotPassedCourses = ({applicationDate, toggleSelectAllFlag, selectAllFlag, courses, selectCourse, selectedCourses, addExams, registeredCourses }) => {
    return (
        <Table celled size={"small"}>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell colSpan={2}>
                        <Header as={'h5'} floated={"left"}>
                            Nepoloženi predmeti dostupni za prijavu
                            <span>
                                                    <Icon/></span>
                            <Header.Subheader>
                                <i>Datum prijave: {moment(applicationDate).format('D. M. YYYY.')}</i>
                            </Header.Subheader>
                        </Header>
                    </Table.HeaderCell>

                </Table.Row>
                {courses.length !== 0 && <Table.Row>
                    <Table.HeaderCell colSpan={1}>
                        <Checkbox
                            label='Odaberi sve'
                            onChange={toggleSelectAllFlag}
                            checked={selectAllFlag}
                            // disabled={registeredCourses}
                        />
                    </Table.HeaderCell>

                </Table.Row>}
            </Table.Header>
            {courses.length !== 0 ? <Table.Body>
                    {courses.map((el, index)=>(
                        <Table.Row key={index}>
                            <Table.Cell colSpan={2}>

                                <Checkbox
                                    disabled={registeredCourses.filter((e)=>(e.courseStudyProgram.course.id === el.course.id)).length !== 0 }
                                    label={el.course.nameBosnian}
                                    onChange={()=>selectCourse(el)}
                                    checked={selectedCourses.length !== 0 ?( selectedCourses.filter((e)=>(e === el)).length !== 0): false}

                                />
                                {el.mandatory?null:<span size="tiny" as="a" basic style={{ float: 'right', color: '#a6a4a1' }}>izborni</span>}

                            </Table.Cell>
                        </Table.Row>))
                    }
                    <Table.Row >
                        <Table.Cell colSpan={2}>
                            <Button floated={"right"} basic size={"mini"} color={"teal"} onClick={addExams}>{'Prijavi ispite'}</Button>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
                :
                <Table.Row >
                    <Table.Cell >
                        <p>Polaznik nema nepoloženih predmeta.</p>
                    </Table.Cell>
                </Table.Row>

            }
        </Table>
    );
};

export default NotPassedCourses;
