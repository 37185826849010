import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Input, Loader, Segment, SegmentGroup} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import {UserRoleContext} from "../../../Main";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'graduationExam':
            return {...state, graduationExam: action.payload};
        case 'fileNumber':
            return {...state, fileNumber: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'dateOfStudentApplication':
            return {...state, dateOfStudentApplication: action.payload};
        case 'courses':
            return {...state, courses: action.payload};
        case 'course':
            return {...state, course: action.payload};
        case 'openForm':
            return {...state, openForm: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const GraduationExamRegistration = ({graduationExam, getGraduationInfo}) => {
    const duration = parseInt(localStorage.getItem('duration'));
    const sspId = parseInt(localStorage.getItem('sspId'))
    const [fileNumberErr, setFileNumberErr] = useState(false);
    const [fileDateErr, setFileDateErr] = useState(false);
    const [courseErr, setCourseErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [dateOfStudentApplicationErr, setDateOfStudentApplicationErr] = useState(false);
    const [state, dispatch] = useReducer(reducer,{
        openForm: false,
        loader: false
    });

    useEffect(() => {
        if (graduationExam){
            dispatch({type: 'graduationExam', payload: graduationExam});
            dispatch({type: 'fileNumber', payload: graduationExam.fileNumber});
            dispatch({type: 'fileDate', payload: new Date(graduationExam.fileDate)});
            dispatch({type: 'dateOfStudentApplication', payload:  new Date(graduationExam.dateOfStudentApplication)});
            dispatch({type: 'course', payload: graduationExam.courseStudyProgram });
        }else{
            getCourses(localStorage.getItem('studyProgramId'));
            dispatch({type: 'openForm', payload: true});
        }

    },[graduationExam]);

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function openForm(){
        getCourses(localStorage.getItem('studyProgramId'));

        dispatch({type: 'fileNumber', payload: state.fileNumber});
        dispatch({type: 'fileDate', payload: new Date(state.fileDate)});
        dispatch({type: 'dateOfStudentApplication', payload:  new Date(state.dateOfStudentApplication)});
        dispatch({type: 'course', payload: state.course});

        dispatch({type: 'openForm', payload: true});

    }


    function createExamRegistration(edit = false) {
        if (!state.fileNumber || !state.fileDate || !state.dateOfStudentApplication || !state.course) {
            toast('Niste unijeli sve podatke.');
            if (!state.fileNumber) { setFileNumberErr(true); } else { setFileNumberErr(false); }
            if (!state.fileDate) { setFileDateErr(true); } else { setFileDateErr(false); }
            if (!state.course) { setCourseErr(true); } else { setCourseErr(false); }
            if (!state.dateOfStudentApplication) { setDateOfStudentApplicationErr(true); } else { setDateOfStudentApplicationErr(false); }
        } else {
            let obj = {};
            if (edit) {
                obj = {...state.graduationExam}
            }
            obj = {
                ...obj,
                studentStudyProgram: {id: sspId},
                fileNumber: state.fileNumber,
                fileDate: state.fileDate,
                courseStudyProgram: state.course,
                dateOfStudentApplication: state.dateOfStudentApplication,
            };


            if (edit) {
                axios.put('/graduation-exam/edit', {...obj}).then(
                    (res) => {
                        getGraduationInfo();
                        toast('Uspješno ste izmijenili podatke.');
                        dispatch({type: 'openForm', payload: false});
                    }).catch((err) => {
                    console.log('err', err)
                });
            } else {
                axios.post('/graduation-exam/save', {...obj}).then(
                    (res) => {
                        getGraduationInfo();
                        toast('Uspješno ste dodali podatke.');
                        dispatch({type: 'openForm', payload: false});
                    }).catch((err) => {
                    console.log('err', err)
                })
            }
        }

    }

     function getCourses(id) {
         const gradeNumber = duration;
         axios.get(`/course-study-program/by-study-program/${id}`,).then(
             (res) => {
                 let courses = res.data.map((el)=>({...el, key: el.id, text: el.course.nameBosnian, description:  el.grade.name, value: el})).filter((e)=>(e.grade.number === gradeNumber));
                 dispatch({type: 'courses', payload: courses})
             }).catch((err) => {
             console.log('err', err)
         });

     }

     function downloadGraduationExamRegistration(){
         axios({
             method: 'post',
             url: `/document/graduation-exam-application-form/${sspId}`,
             responseType: 'blob',
             // data: { ...obj }
         })
             .then(res => {
                 if (res.data.size === 0) {
                     return;
                 }
                 const fileName = extractFileNameFromHeaders(res.headers);
                 download(res.data, fileName);
                 return res;
             })
             .catch(err => {
                 toast('Došlo je do greške!');
             });
     }
    return (
        <div>

            {state.loader?<div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                :<Table celled size={"small"}>
                    <Table.Header>
                        <Table.HeaderCell colSpan={state.openForm?2:1}> {duration === 3 ?'Prijava završnog ispita':'Prijava maturskog ispita' }</Table.HeaderCell>
                        {!state.openForm && <Table.HeaderCell textAlign="center" >
                            <Popup size={"small"}
                                   content='Izmijeni'
                                   trigger={
                                       <Button
                                           icon
                                           size={"tiny"}
                                           basic
                                           compact
                                           disabled={activeRole === "ROLE_ORGANIZER" && graduationExam.serialNumber}
                                           floated={"right"}
                                           onClick={openForm}
                                       >
                                           <Icon name='pencil' color={"teal"} />
                                       </Button>
                                   }
                            />
                        </Table.HeaderCell>}

                    </Table.Header>
                    <Table.Body>
                        <Table.Row >
                            <Table.Cell> <Table.HeaderCell >
                                <Form>
                                    <Form.Field required>
                                        <label>Djelovodni broj</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell> Djelovodni broj </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite broj"
                                    value={state.fileNumber}
                                    name="fileNumber"
                                    onChange={handleInputChange}
                                    error={fileNumberErr}
                                />
                                </Form></Table.Cell>:
                                <Table.Cell >{state.fileNumber && state.fileNumber}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row >
                            <Table.Cell><Table.HeaderCell >
                                <Form>
                                    <Form.Field required>
                                        <label>Datum prijema prijave</label>
                                    </Form.Field>
                                </Form> </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell><Table.HeaderCell>  Datum prijema prijave </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell>
                                    <Form size={'tiny'}>

                                        <Form.Field
                                            required
                                            control={DateTimePicker}
                                            format="D. M. YYYY."
                                            placeholder="Unesite datum"
                                            name="dateOfStudentApplication"
                                            value={state.dateOfStudentApplication}
                                            time={false}
                                            onChange={(e) => handleDateChange('dateOfStudentApplication', e)}
                                            style={{ borderWidth: '0px' }}
                                            error={dateOfStudentApplicationErr}
                                        />

                                    </Form>
                                </Table.Cell>:
                                <Table.Cell>{state.dateOfStudentApplication && moment(state.dateOfStudentApplication).format('D. M. YYYY.')}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell><Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Datum prijave ispita</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell><Table.HeaderCell>Datum prijave ispita  </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell>
                                <Form size={'tiny'}>
                                    <Form.Field
                                        required
                                        error={fileDateErr}
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="fileDate"
                                        value={state.fileDate}
                                        time={false}
                                        onChange={(e) => handleDateChange('fileDate', e)}
                                        style={{ borderWidth: '0px' }}
                                    />
                                </Form>
                            </Table.Cell>: <Table.Cell>{state.fileDate && moment(state.fileDate).format('D. M. YYYY.')}</Table.Cell>}
                        </Table.Row>

                         <Table.Row width={8}>
                            <Table.Cell> <Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Predmet koji se polaže</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell>Predmet koji se polaže</Table.HeaderCell></Table.Cell>*/}
                             {state.openForm ?
                            <Table.Cell width={8}>
                                <Form size={'tiny'}>
                                    <Form.Field required>
                                        <Form.Dropdown
                                            error={courseErr}
                                            clearable
                                            placeholder="Odaberite predmet"
                                            value={state.course}
                                            onChange={handleInputChange}
                                            name="course"
                                            options={state.courses}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>
                                </Form>
                            </Table.Cell> : <Table.Cell width={8}>{state.course && state.course.course.nameBosnian}</Table.Cell>
                             }
                        </Table.Row>

                        {state.openForm ? <Table.Row>
                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">
                                <Button size={"mini"} basic color={"teal"} onClick={()=>graduationExam.id ? createExamRegistration(true) :createExamRegistration(false)} >Sačuvaj</Button>
                                <Button size={"mini"} basic color={"black"} onClick={()=>{ dispatch({type: 'openForm', payload: false})}} >Odustani</Button>
                            </Table.Cell>

                        </Table.Row>: <Table.Row >
                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">
                                <Button disabled={activeRole === "ROLE_MINISTRY" || activeRole === "ROLE_ADMIN"} icon labelPosition='left' size="mini" basic color="black" onClick={downloadGraduationExamRegistration} >
                                    <Icon name='download' color={"teal"} />
                                    Preuzmi prijavu i zapisnik
                                </Button>

                            </Table.Cell>

                        </Table.Row>}

                    </Table.Body>

                </Table>}

            <ToastContainer  />

        </div>
    );
};

export default GraduationExamRegistration;
