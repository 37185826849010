import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Form, Input, Loader} from "semantic-ui-react";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
import {UserRoleContext} from "../../../Main";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
const reducer = (state, action)=> {
    switch (action.type) {
        case 'courseStudyProgramFirstQuestion':
            return {...state, courseStudyProgramFirstQuestion: action.payload};
        case 'courseStudyProgramSecondQuestion':
            return {...state, courseStudyProgramSecondQuestion: action.payload};
        case 'courseStudyProgramThirdQuestion':
            return {...state, courseStudyProgramThirdQuestion: action.payload};
        case 'committee':
            return {...state, committee: action.payload};
        case 'committees':
            return {...state, committees: action.payload};
        case 'mark':
            return {...state, mark: action.payload};
        case 'marks':
            return {...state, marks: action.payload};
        case 'date':
            return {...state, date: action.payload};
        case 'openForm':
            return {...state, openForm: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        default:
            return state;

    }
};
const VerbalOptionalCourse = ({graduationExam, getGraduationInfo}) => {
    const [dateErr, setDateErr] = useState(false);
    const [markErr, setMarkErr] = useState(false);
    const [committeeErr, setCommitteeErr] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        openForm: false,
        loader: false
    });

    useEffect(() => {
        if (graduationExam){
            dispatch({type: 'courseStudyProgramFirstQuestion', payload: graduationExam.courseStudyProgramFirstQuestion});
            dispatch({type: 'courseStudyProgramSecondQuestion', payload: graduationExam.courseStudyProgramSecondQuestion});
            dispatch({type: 'courseStudyProgramThirdQuestion', payload: graduationExam.courseStudyProgramThirdQuestion});
            dispatch({type: 'date', payload:graduationExam.courseStudyProgramMarkDate? new Date(graduationExam.courseStudyProgramMarkDate):null});
            dispatch({type: 'mark', payload: graduationExam.courseStudyProgramMark});
            dispatch({type: 'committee', payload: graduationExam.committeeForCourseStudyProgram});
            if(!graduationExam.courseStudyProgramMark?.id){ openForm()}
        }

    },[graduationExam]);


    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    function openForm(){
        activeRole === "ROLE_ORGANIZER" && getCommittees();
        activeRole === "ROLE_ADMIN" && getCommitteeForAdmin();
        getMarks();

        dispatch({type: 'courseStudyProgramFirstQuestion', payload: graduationExam.courseStudyProgramFirstQuestion});
        dispatch({type: 'courseStudyProgramSecondQuestion', payload: graduationExam.courseStudyProgramSecondQuestion});
        dispatch({type: 'courseStudyProgramThirdQuestion', payload: graduationExam.courseStudyProgramThirdQuestion});
        dispatch({type: 'date', payload:graduationExam.courseStudyProgramMarkDate? new Date(graduationExam.courseStudyProgramMarkDate):null});
        dispatch({type: 'mark', payload: graduationExam.courseStudyProgramMark});
        dispatch({type: 'committee', payload: graduationExam.committeeForCourseStudyProgram});

        dispatch({type: 'openForm', payload: true});

    }
    function updateGraduationInfo() {
        if (!state.date || !state.mark || !state.committee) {
            toast('Niste unijeli sve podatke.');
            if (!state.date) { setDateErr(true); } else { setDateErr(false); }
            if (!state.mark) { setMarkErr(true); } else { setMarkErr(false); }
            if (!state.committee) { setCommitteeErr(true); } else { setCommitteeErr(false); }
        } else {
            let obj = {
                ...graduationExam,
                courseStudyProgramMarkDate: state.date,
                courseStudyProgramMark: state.mark,
                committeeForCourseStudyProgram: state.committee,
                courseStudyProgramFirstQuestion: state.courseStudyProgramFirstQuestion,
                courseStudyProgramSecondQuestion: state.courseStudyProgramSecondQuestion,
                courseStudyProgramThirdQuestion: state.courseStudyProgramThirdQuestion,

            };

            axios.put('/graduation-exam/edit', {...obj}).then(
                (res) => {
                    getGraduationInfo();
                    toast('Uspješno ste izmijenili podatke.');
                    dispatch({type: 'openForm', payload: false});
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function getMarks(){
        axios.get('/mark/all',).then(
            (res) => {
                let marks = res.data.map((el)=>({key: el.id, text:  el.name + ' (' + el.number +')', description:  el.name, value: el})).reverse();
                dispatch({type: 'marks', payload: marks});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getCommittees(){

        axios.get('/committee/by-organizer',).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), description: el.firstMember.person.lastName + ', ' + el.secondMember.person.lastName + ', ' +  el.thirdMember.person.lastName, value: el}));
                dispatch({type: 'committees', payload: committees});

            }).catch((err) => {
            console.log('err', err)
        });
    }

    //for admin
    function getCommitteeForAdmin(id){
        const organizerId = parseInt(localStorage.getItem('organizerId'));
        axios.get(`/committee/by-organizer-id/${organizerId}`).then(
            (res) => {
                let committees = res.data.map((el)=>({key: el.key, text: (el.name), value: el}));
                dispatch({type: 'committees', payload: committees});
            }).catch((err) => {
            console.log('err', err)
        });
    }
    return (
        <div>

            {state.loader?<div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                :<Table celled size={"small"}>
                    <Table.Header>
                        <Table.HeaderCell colSpan={state.openForm?2:1}> Usmeni ispit </Table.HeaderCell>
                        {!state.openForm && <Table.HeaderCell textAlign="center" >
                            <Popup size={"small"}
                                   content='Izmijeni'
                                   trigger={
                                       <Button
                                           icon
                                           size={"tiny"}
                                           basic
                                           compact
                                           disabled={activeRole === "ROLE_ORGANIZER" && graduationExam.serialNumber}
                                           floated={"right"}
                                           onClick={openForm}
                                       >
                                           <Icon name='pencil' color={"teal"} />
                                       </Button>
                                   }
                            />
                        </Table.HeaderCell>}

                    </Table.Header>
                    <Table.Body>
                        <Table.Row width={8}>
                            <Table.Cell><Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Datum ispita</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell><Table.HeaderCell> Datum ispita </Table.HeaderCell></Table.Cell>*/}


                            {state.openForm ? <Table.Cell >
                                <Form size={'tiny'}>
                                    <Form.Field
                                        required
                                        error={dateErr}
                                        control={DateTimePicker}
                                        format="D. M. YYYY."
                                        placeholder="Unesite datum"
                                        name="date"
                                        value={state.date}
                                        time={false}
                                        onChange={(e) => handleDateChange('date', e)}
                                        style={{ borderWidth: '0px' }}
                                    />
                                </Form>
                            </Table.Cell>: <Table.Cell>{state.date && moment(state.date).format('D. M. YYYY.')}</Table.Cell>}
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Ocjena</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell>Ocjena</Table.HeaderCell></Table.Cell>*/}

                            {state.openForm ? <Table.Cell>
                                <Form size={'tiny'}>
                                    <Form.Field required>
                                        <Form.Dropdown
                                            error={markErr}
                                            clearable
                                            placeholder="Odaberite ocjenu"
                                            value={state.mark}
                                            onChange={handleInputChange}
                                            name="mark"
                                            options={state.marks}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>
                                </Form>
                            </Table.Cell>: <Table.Cell >{state.mark && state.mark.name + ' (' + state.mark.number +')'}</Table.Cell>
                            }
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell>
                                <Form>
                                    <Form.Field required>
                                        <label>Komisija</label>
                                    </Form.Field>
                                </Form>
                            </Table.HeaderCell></Table.Cell>
                            {/*<Table.Cell> <Table.HeaderCell>Komisija</Table.HeaderCell></Table.Cell>*/}

                            {state.openForm ? <Table.Cell width={8}>
                                <Form size={'tiny'}>
                                    <Form.Field required>
                                        <Form.Dropdown
                                            error={committeeErr}
                                            clearable
                                            placeholder="Odaberite komisiju"
                                            value={state.committee}
                                            onChange={handleInputChange}
                                            name="committee"
                                            options={state.committees}
                                            search
                                            fluid
                                            selection
                                        />
                                    </Form.Field>
                                </Form>
                            </Table.Cell> :
                                <Table.Cell width={8}>{state.committee && state.committee.name + '  -  ' + state.committee.firstMember.person.firstName + ' '+ state.committee.firstMember.person.lastName + ', ' + state.committee.secondMember.person.firstName + ' ' + state.committee.secondMember.person.lastName + ', ' + state.committee.thirdMember.person.firstName + ' ' + state.committee.thirdMember.person.lastName}</Table.Cell>
                                // <Table.Cell >{state.committee && state.committee.name}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell> Prvo pitanje </Table.HeaderCell></Table.Cell>


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite pitanje"
                                    value={state.courseStudyProgramFirstQuestion}
                                    name="courseStudyProgramFirstQuestion"
                                    onChange={handleInputChange}
                                    //error={fileNumberErr}
                                />
                                </Form></Table.Cell>:
                                <Table.Cell >{state.courseStudyProgramFirstQuestion && state.courseStudyProgramFirstQuestion}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell> Drugo pitanje </Table.HeaderCell></Table.Cell>


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite pitanje"
                                    value={state.courseStudyProgramSecondQuestion}
                                    name="courseStudyProgramSecondQuestion"
                                    onChange={handleInputChange}
                                    //error={fileNumberErr}
                                />
                                </Form></Table.Cell>:
                                <Table.Cell >{state.courseStudyProgramSecondQuestion && state.courseStudyProgramSecondQuestion}</Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell> <Table.HeaderCell> Treće pitanje </Table.HeaderCell></Table.Cell>


                            {state.openForm ? <Table.Cell ><Form size={'tiny'}><Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite pitanje"
                                    value={state.courseStudyProgramThirdQuestion}
                                    name="courseStudyProgramThirdQuestion"
                                    onChange={handleInputChange}
                                    //error={fileNumberErr}
                                />
                                </Form></Table.Cell>:
                                <Table.Cell >{state.courseStudyProgramThirdQuestion && state.courseStudyProgramThirdQuestion}</Table.Cell>
                            }
                        </Table.Row>
                        {state.openForm ? <Table.Row >
                            <Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">
                                <Button size={"mini"} basic color={"teal"} onClick={updateGraduationInfo} >Sačuvaj</Button>
                                <Button size={"mini"} basic color={"black"} onClick={()=>{ dispatch({type: 'openForm', payload: false})}} >Odustani</Button>
                            </Table.Cell>

                        </Table.Row>: <Table.Row >
                            {/*<Table.Cell colSpan={state.openForm ? 3: 2} textAlign="right">*/}
                            {/*    <Button icon labelPosition='left' size="mini" basic color="black"  >*/}
                            {/*        <Icon name='download' color={"teal"} />*/}
                            {/*        Preuzmi prijavu i zapisnik*/}
                            {/*    </Button>*/}

                            {/*</Table.Cell>*/}

                        </Table.Row>}
                    </Table.Body>

                </Table>}
            <ToastContainer  />
        </div>
    );
};

export default VerbalOptionalCourse;
