import React, {useContext, useEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Button, Header, Icon, Loader, Menu, Message, Tab} from "semantic-ui-react";

import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {UserRoleContext} from "../../Main";
import CourseStudyProgramCoursesForm from "./CourseStudyProgramCoursesForm";
import CourseTableByGrade from "./CourseTableByGrade";
import CourseTable from "./CourseTable";
import StudyProgramInfo from "./StudyProgramInfo";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'courses':
            return {...state, courses: action.payload};
        case 'coursesId':
            return {...state, coursesId: action.payload};
        case 'course':
            return {...state, course: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'openTable':
            return {...state, openTable: action.payload};
        case 'activeIndex':
            return {...state, activeIndex: action.payload};
        case 'grades':
            return {...state, grades: action.payload};
        case 'panes':
            return {...state, panes: action.payload};
        case 'verified':
            return {...state, verified: action.payload};
        case 'studyProgram':
            return {...state, studyProgram: action.payload};
        case 'readyForVerification':
            return {...state, readyForVerification: action.payload};
        default:
            return state;

    }
};

const Courses = () => {
    const location = useLocation();
    const {name, studyProgramId, organizerId, duration, optLock, formal} = location.state;
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const {activeRole} = useContext(UserRoleContext);
    const [state, dispatch] = useReducer(reducer,{
        courses: [],
        loader: true,
        course: '',
        currentId: null,
        openTable: false,
        coursesId: [],
        activeIndex: 0,
        panes: []

    });

    let toggleAddForm = () =>{
        setShowAddForm(!showAddForm)
    };

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value});
    }

    function deleteCourse(id){
        setCurrentId(id);
        dispatch({type: 'loader', payload: true});
        axios.delete(`/course-study-program/delete/${id}`,).then(
            (res) => {
                getCourses(studyProgramId);
                toast('Uspješno ste izbrisali predmet!');
                setCurrentId(null);
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function getStudyProgram(studyProgramId){
        dispatch({type: 'loader', payload: true});
        axios.get(`/study-program/${studyProgramId}`,).then(
            (res) => {
                let studyProgram = res.data;
                dispatch({type: 'studyProgram', payload: studyProgram});
                dispatch({type: 'verified', payload: studyProgram.verified});
                dispatch({type: 'readyForVerification', payload: studyProgram.readyForVerification});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            console.log('err', err)
        });

    }
    // useEffect(() => {
    //     getStudyProgram(studyProgramId);
    // },[state.verified]);

    useEffect(() => {
        getStudyProgram(studyProgramId);
        getCourses(studyProgramId);
    },[]);

    useEffect(() => {
        getPanes();
    },[duration, state.courses, state.verified]);

    function getCourses(id){
        setCurrentId(id);
        dispatch({type: 'openTable', payload: true});
        axios.get(`/course-study-program/by-study-program/${id}`,).then(
            (res) => {
                let courses = res.data;
                dispatch({type: 'courses', payload: courses});
                setCurrentId(null);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function setCourse(el){
        dispatch({type: 'course', payload: el})
    }

    function getPanes(){
        let panesLocal = [];
        for(let i=0; i<duration;i++){
            panesLocal.push(
                {
                    menuItem:{ content: <strong>{i+1}. razred</strong> },
                    render: () => (<Tab.Pane as={"div"}>
                       <CourseTableByGrade courses={state.courses} grade={i+1} setShowEditForm={setShowEditForm} setCourse={setCourse} deleteCourse={deleteCourse} verified={state.verified} readyForVerification={state.readyForVerification} />
                    </Tab.Pane>)
                }
            );
        }
        dispatch({type: 'panes', payload: panesLocal});
    }

    function handleTabChange(e, { activeIndex }) {
        setShowAddForm(false);
        setShowEditForm(false);
        dispatch({type: 'activeIndex', payload: activeIndex});
    }

    function verifyProgram() {
        dispatch({type: 'loader', payload: true});
        axios.put(`study-program/verify/${studyProgramId}/${state.studyProgram.optLock}`,).then(
            (res) => {
            getStudyProgram(studyProgramId);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    function cancelProgram(reason) {
        let obj = {
            id: studyProgramId,
            optLock: state.studyProgram.optLock,
            declineVerificationReason: reason
        }
        dispatch({type: 'loader', payload: true});
        axios.put('study-program/decline-verification', {...obj}).then(
            (res) => {
                getStudyProgram(studyProgramId);
                toast('Verifikacija nastavnog plana je odbijena, razlog uspješno poslan.')
            }).catch((err) => {
            console.log('err', err)
        });
    }
    function unverifyProgram() {
        dispatch({type: 'loader', payload: true});
        axios.put(`study-program/cancel-verification/${studyProgramId}/${state.studyProgram.optLock}`,).then(
            (res) => {
            getStudyProgram(studyProgramId);
            }).catch((err) => {
            console.log('err', err)
        });
    }

    return (
        <div>
            <div>
                <Segment.Group >
                    <Segment className={'customPadding'} clearing color={"teal"}>

                        <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>

                            <Menu.Item >

                               {!state.loader && state.verified? <Icon name='check circle' color={"blue"} size={'large'} />: <Icon name='attention' color={"red"} size={'large'}/>}


                            </Menu.Item>
                            <Menu.Item >
                                <Header as={'h3'} floated={"left"}>
                                    {name}
                                    <span><Icon/></span>
                                    <Header.Subheader>
                                        <i>Nastavni program</i>
                                    </Header.Subheader>
                                </Header>
                            </Menu.Item>
                            <Menu.Menu position={'right'}>
                                <Menu.Item>
                                    <span>
                                        <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                            <Icon name='arrow left'/>
                                        </Button>
                                            <Icon/>
                                    </span>
                                </Menu.Item>
                            </Menu.Menu>

                        </Menu>

                    </Segment>
                    <Segment clearing>
                        <StudyProgramInfo studyProgram={state.studyProgram} verifyProgram={verifyProgram} unverifyProgram={unverifyProgram} cancelProgram={cancelProgram} getStudyProgram={getStudyProgram} />
                    </Segment>

                    <Segment clearing>
                        {(!showEditForm && !state.verified && !state.loader && !state.studyProgram.readyForVerification) && <>
                            <Button icon labelPosition='left' size="small" basic color="black" onClick={toggleAddForm} floated={"right"}>
                                <Icon name='add' color={"teal"}/>
                                Dodaj predmet / modul / ispit na program
                            </Button>
                        </>}
                    </Segment>

                    {showAddForm &&
                    <Segment>
                        <CourseStudyProgramCoursesForm add toggleEditForm={toggleAddForm} getCourses={getCourses} addedCourses={state.courses} duration={duration} formal={formal}/>
                    </Segment>}

                    {showEditForm &&
                    <Segment>
                        <CourseStudyProgramCoursesForm edit toggleEditForm={toggleEditForm} getCourses={getCourses} addedCourses={state.courses} course={state.course} duration={duration} formal={formal}/>
                    </Segment>}
                    <Segment >
                        {state.loader ?
                            <div style={{paddingTop: '50px', paddingBottom: '50px'}}>
                            </div> :
                           formal? <Tab menu={{secondary: true, pointing: true, color: 'teal'}} panes={state.panes}
                                 activeIndex={state.activeIndex} onTabChange={handleTabChange}/>:
                               <CourseTable courses={state.courses} setShowEditForm={setShowEditForm} setCourse={setCourse} deleteCourse={deleteCourse} verified={state.verified} readyForVerification={state.readyForVerification} />
                        }
                    </Segment>

                </Segment.Group>
                <ToastContainer  />

            </div>
        </div>
    );
};

export default Courses;
