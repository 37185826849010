import React, {useEffect, useState, useContext} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import {Form, Input} from "semantic-ui-react";
import {DateTimePicker} from "react-widgets";
import axios from "axios";
import {UserRoleContext} from "../../Main";

const PersonInfo = ({student, teacher, person, edit, jmbg, firstName, lastName, maidenName, gender, dateOfBirth, citizenship, handleInputChange, handleDateChange}) => {
    const [genders, setGenders] = useState([]);
    const {activeRole} = useContext(UserRoleContext);
    useEffect(() => {
        axios.get('/gender/all',).then(
            (res)=>{
                let genderOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
               setGenders(genderOptions);
            }).catch((err)=>{
            console.log('err', err)
        });
    },[]);

    return (
        <>
            {!teacher &&<Table.Row>
                <Table.Cell> <Table.HeaderCell>OID </Table.HeaderCell></Table.Cell>
                {edit && activeRole === "ROLE_ADMIN" ? <Table.Cell >
                        <Form size={'tiny'}>
                            <Form.Group widths={'equal'}>
                                <Form.Field
                                    required
                                    control={Input}
                                    placeholder="Unesite JMBG"
                                    value={jmbg}
                                    name="jmbg"
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Form>
                    </Table.Cell> :
                <Table.Cell width={10}>{person ? person.oid : ''}</Table.Cell>}
            </Table.Row>}
            <Table.Row >
                <Table.Cell><Table.HeaderCell> JMBG </Table.HeaderCell></Table.Cell>
                <Table.Cell>{person ? person.jmbg : ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell> <Table.HeaderCell>Ime i prezime </Table.HeaderCell></Table.Cell>

                {edit ? <Table.Cell >
                    <Form size={'tiny'}>
                        <Form.Group widths={'equal'}>
                            <Form.Field
                                required
                                control={Input}
                                placeholder="Unesite ime"
                                value={firstName}
                                name="firstName"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                            <Form.Field
                                required
                                control={Input}
                                placeholder="Unesite prezime"
                                value={lastName}
                                name="lastName"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                        </Form.Group>
                    </Form>
                </Table.Cell>:
                    <Table.Cell>{person ? person.firstName : ''} {person ? person.lastName : ''}</Table.Cell>
                }
            </Table.Row>
            {person && person.gender.key === 'f' && <Table.Row>
                 <Table.Cell> <Table.HeaderCell>Djevojačko prezime</Table.HeaderCell></Table.Cell>
                {edit ? <Table.Cell>
                    <Form size={'tiny'}>
                        <Form.Field required>
                            <Form.Field
                                required
                                control={Input}
                                placeholder="Unesite prezime"
                                value={maidenName}
                                name="maidenName"
                                onChange={handleInputChange}
                                // error={this.state.jmbgErr}
                            />
                        </Form.Field>
                    </Form>
                </Table.Cell>:
                    <Table.Cell>{person ? person.maidenName : ''}</Table.Cell>}


            </Table.Row>}
            <Table.Row>
                <Table.Cell> <Table.HeaderCell>Spol</Table.HeaderCell></Table.Cell>
                {edit ? <Table.Cell>
                    <Form size={'tiny'}>
                        <Form.Field required>
                            <Form.Dropdown
                                clearable
                                placeholder="Odaberite spol"
                                value={gender}
                                onChange={handleInputChange}
                                name="gender"
                                options={genders}
                                search
                                fluid
                                selection
                            />
                        </Form.Field>
                    </Form>
                </Table.Cell>: <Table.Cell>{person ? person.gender.name: ''}</Table.Cell>}
            </Table.Row>
            <Table.Row>
                <Table.Cell> <Table.HeaderCell>Datum rođenja</Table.HeaderCell></Table.Cell>
                {edit ? <Table.Cell >
                    <Form size={'tiny'}>
                        <Form.Field
                            required
                            control={DateTimePicker}
                            format="D. M. YYYY."
                            placeholder="Unesite datum"
                            name="dateOfBirth"
                            value={dateOfBirth}
                            time={false}
                            onChange={(e) => handleDateChange('dateOfBirth', e)}
                            style={{ borderWidth: '0px' }}
                        />
                    </Form>
                </Table.Cell>:
                    <Table.Cell>{person ? moment(person.dateOfBirth).format('D. M. YYYY.') : ''}</Table.Cell>
                }
            </Table.Row>
            {student && <Table.Row>
                <Table.Cell> <Table.HeaderCell>Državljanstvo</Table.HeaderCell></Table.Cell>
                    <Table.Cell>{ citizenship.length !== 0 ? citizenship[0].country.name : ''}</Table.Cell>
            </Table.Row>}
        </>
    );
};

export default PersonInfo;
