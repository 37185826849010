import React, {Fragment, useContext, useState} from 'react';
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import Menu from "semantic-ui-react/dist/commonjs/collections/Menu";
import Image from "semantic-ui-react/dist/commonjs/elements/Image";
import UserService from "../../service/UserService";
import {UserRoleContext} from "../../Main";
import {Link} from "react-router-dom";
import {Divider} from "semantic-ui-react";
import {specialCharMap} from "@testing-library/user-event/dist/keyboard";
import {downloadStudentRegister} from "../Utilities/Util";


let items = (activeRole, activeRoleId, roles = [], mobileVersion = false) => (<Fragment>
    {/*<Menu.Item header>ISKOOM</Menu.Item>*/}

    {activeRole==='ROLE_ADMIN' || activeRole==='ROLE_MINISTRY' ? <Dropdown item text='Organizatori'>
        <Dropdown.Menu>
            <Dropdown.Item icon='group' text='Pregled' as={Link} to={'/organizatori'}/>
            {/*<Dropdown.Item icon='download' text='Registar organizatora' />*/}
            <Divider/>
            <Dropdown.Item icon='newspaper outline' as={Link} text='Konkursi' to='/organizatori/konkursi'/>
            <Dropdown.Item icon='file alternate outline' as={Link} text='Ugovori' to='/organizatori/ugovori'/>
            <Dropdown.Item icon='handshake' text='Poslodavci' as={Link} to='/organizatori/poslodavci'/>

            {/*<Dropdown.Item icon='map' text='Mapa' as={Link} to='/organizatori/mapa'/>*/}
        </Dropdown.Menu>
    </Dropdown>:
        <Dropdown item text='Organizator'>
            <Dropdown.Menu>
                <Dropdown.Item icon='user' text='Profil' as={Link} to='/profil'/>
                <Divider/>
                <Dropdown.Item icon='newspaper outline' text='Konkursi' as={Link} to='/organizatori/konkursi'/>
                <Dropdown.Item icon='file alternate outline' text='Ugovori' as={Link} to='/organizatori/ugovori'/>
                <Dropdown.Item icon='handshake' text='Poslodavci' as={Link} to='/organizatori/poslodavci'/>

                {/*<Dropdown.Item icon='map' text='Mapa' as={Link} to='/organizatori/mapa'/>*/}
            </Dropdown.Menu>
        </Dropdown>

    }
    <Dropdown item text='Polaznici'>
        <Dropdown.Menu>
            <Dropdown.Item icon='group' text='Pregled' as={Link} to='/polaznici'/>
            <Dropdown.Item icon='folder open' text='Matične knjige' as={Link} to='/polaznici/maticne-knjige'/>
            {/*{activeRole ==='ROLE_ORGANIZER' && <Dropdown.Item icon='download' text='Registar polaznika' onClick={()=>{downloadStudentRegister()}}/>}*/}
            <Dropdown.Item icon='download' text='Registar polaznika' as={Link} to='/polaznici/registar-studenata'/>
            {/*<Dropdown.Item icon='pencil' text='Zahtjevi za promjenu podataka' as={Link} to='/polaznici/zahtjevi-za-promjenu-podataka'/>*/}
        </Dropdown.Menu>
    </Dropdown>
    <Dropdown item text='Nastava'>
        <Dropdown.Menu>
            {activeRole ==='ROLE_ORGANIZER' && <Dropdown.Item icon='briefcase' text='Zanimanja' as={Link} to='/zanimanja'/>}
            {activeRole !=='ROLE_ORGANIZER' && <Dropdown.Item icon='briefcase' text='Zanimanja' as={Link} to='/sifrarnici/zanimanja'/>}
            <Dropdown.Item icon='edit' text='Predmeti' as={Link} to='/nastava/predmeti'/>
            <Dropdown.Item icon='file alternate' text='Programi' as={Link} to='/npp'/>
            {/*<Dropdown.Item icon='pencil' text='Zahtjevi za promjenu podataka' as={Link} to='/polaznici/zahtjevi-za-promjenu-podataka'/>*/}
        </Dropdown.Menu>
    </Dropdown>
    {/*<Menu.Item*/}
    {/*    as={Link}*/}
    {/*    to='/npp'*/}
    {/*    name='notification'*/}
    {/*    content='NPP'*/}
    {/*/>*/}

    {/*<Menu.Item*/}
    {/*    as={Link}*/}
    {/*    to='/nastavnici'*/}
    {/*    name='documentation'*/}
    {/*    content='Nastavnici'*/}
    {/*/>*/}
    <Dropdown item text='Nastavnici'>
        <Dropdown.Menu>
            <Dropdown.Item icon='group' text='Pregled' as={Link} to='/nastavnici'/>
            <Dropdown.Item icon='balance scale' text='Komisije' as={Link} to='/komisije' />
            <Dropdown.Item icon='user' text='Voditelj obrazovne grupe' as={Link} to='/voditelj-obrazovne-grupe' />
        </Dropdown.Menu>
    </Dropdown>
    <Dropdown item text='Andragoška dokumentacija'>
        <Dropdown.Menu>
            {/*{activeRole ==='ROLE_ADMIN' && <Dropdown.Item icon='tasks' text='Zaduženja' as={Link} to='/andragoska-dokumentacija'/>}*/}
            <Dropdown.Item icon='file alternate outline' text='Obrasci' as={Link} to='/andragoska-dokumentacija/obrasci' />
            {/*{activeRole ==='ROLE_ADMIN' && <Dropdown.Item icon='database' text='Zalihe' as={Link} to='/andragoska-dokumentacija/zalihe'/>}*/}
            {/*{activeRole ==='ROLE_ADMIN' && <Dropdown.Item icon='copy outline' text='Izvještaji' as={Link} to='/andragoska-dokumentacija/izvjestaji' />}*/}
        </Dropdown.Menu>
    </Dropdown>
    <Dropdown item text='Zahtjevi'>
        <Dropdown.Menu>
            <Dropdown.Item icon='exchange' text='Zahtjevi za promjenu podataka' as={Link} to='/polaznici/zahtjevi-za-promjenu-podataka' />
            <Dropdown.Item icon='mail' text='Ostali zahtjevi' as={Link} to='/komentari-i-prijedlozi' />
        </Dropdown.Menu>
    </Dropdown>
    {/*<Dropdown item text='Statistika'>*/}
    {/*    <Dropdown.Menu>*/}
    {/*        <Dropdown.Item icon='edit' text='Statistika OOO' />*/}
    {/*        <Dropdown.Item icon='globe' text='Zavod za statistiku' />*/}
    {/*        <Dropdown.Item icon='settings' text='Account Settings' />*/}
    {/*    </Dropdown.Menu>*/}
    {/*</Dropdown>*/}
    {(activeRole==='ROLE_ADMIN' || activeRole==='ROLE_MINISTRY') &&
    <Dropdown item text={'Sistem'} >
        <Dropdown.Menu>
            {activeRole ==='ROLE_ADMIN' && <Dropdown.Item icon='group' text='Korisnici' as={Link} to='/korisnici' />}
            {activeRole ==='ROLE_ADMIN' && <Divider/>}
            <Dropdown.Item icon='chart bar outline' text='Statistika OOO' as={Link} to='/statistika' />
            {/*<Dropdown.Item icon='chart pie' text='Zavod za statistiku' />*/}
            {/*<Divider/>*/}
            {/*<Dropdown.Item icon='server' text='Backup' />*/}
            {/*<Dropdown.Item icon='settings' text='Pristup sistemu' />*/}
            {/*<Dropdown.Item icon='clock outline' text='Pristup sistemu po satima' />*/}
            <Divider/>
            {/*<Dropdown.Item icon='bell outline' text='Obavještenja' />*/}
            <Dropdown.Item icon='list alternate outline' text='Šifrarnici' as={Link} to='/sifrarnici' />
            {/*<Dropdown.Item icon='mail' text='Komentari i prijedlozi' />*/}
        </Dropdown.Menu>
    </Dropdown>
// :
//         <Menu.Item
//         as={Link}
//         to='/komentari-i-prijedlozi'
//         // name='documentation'
//         content='Komentari i prijedlozi'
//         />
    }

    {(activeRole === 'ROLE_ORGANIZER') &&
    <Menu.Item
        as={Link}
        to='/statistika'
        // name='documentation'
        content='Statistika'
        />
    }

    {
        mobileVersion? <Dropdown item  icon='user' >
            <Dropdown.Menu>
                {roles.length !== 0 && roles.map((e)=>(<Dropdown.Item key={e.id} icon='user'  text={e.role.name}  as={Link} to={'/u/' + e.ordinal}  />))}
                <Divider/>
                <Dropdown.Item icon='sign out' text='Odjavi se' onClick={() =>{ UserService.doLogout(); window.localStorage.clear()}}/>
            </Dropdown.Menu>
        </Dropdown>:
            <Menu.Menu position={'right'}>
                <Dropdown item  icon='user' >
                    <Dropdown.Menu>
                        {roles.length !== 0 &&  roles.map((e)=>(<Dropdown.Item key={e.id} icon='user' active={e.id === activeRoleId}  text={ (e.userOrganizer.organizer)? e.role.name +': '+ e.userOrganizer.organizer.name:e.role.name} as={Link} to={'/u/' + e.ordinal} />))}
                        <Divider/>
                        <Dropdown.Item icon='sign out' text='Odjavi se' onClick={() => {UserService.doLogout(); window.localStorage.clear()}}/>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
    }


</Fragment>);



const MainMenu = (props) => {
    const {userRoles, activeRoleId, activeRole} = useContext(UserRoleContext);
    return (

            <Menu borderless fluid size={"large"} vertical={false} >
                <Menu.Item
                    header
                    to='/'
                    as={Link}
                    name='home'
                    content={<Image size={'mini'} src={'../../../img/LogotipOO_mali.gif'}  verticalAlign='middle' /> }
                />
                {/*<Menu.Item header >ISKOOM</Menu.Item>*/}
                {props.dropdown?
                    <Dropdown item text='Sadržaj'>
                        <Dropdown.Menu>
                           { items(activeRole, activeRoleId, userRoles, props.dropdown)}
                        </Dropdown.Menu>
                    </Dropdown>:
                    items(activeRole, activeRoleId, userRoles)
                }

            </Menu>

    );
};

export default MainMenu;
