import React, {useEffect, useReducer, useState, useContext} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import moment from "moment";
import {Form, Grid, GridColumn, Header, Input, Loader, Menu, Segment} from "semantic-ui-react";
import axios from "axios";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import {extractFileNameFromHeaders} from "../../Utilities/Util";
import download from "downloadjs";
import {toast, ToastContainer} from "react-toastify";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import {DateTimePicker} from "react-widgets";
import Exams from "./Exams";
import Marks from "./Marks";
import {UserRoleContext} from "../../../Main";

const reducer = (state, action)=> {
    switch (action.type) {
        case 'examRegistrations':
            return {...state, examRegistrations: action.payload};
        case 'notPassedCourses':
            return {...state, notPassedCourses: action.payload};
        case 'applicationDate':
            return {...state, applicationDate: action.payload};
        case 'fileNumber':
            return {...state, fileNumber: action.payload};
        case 'fileDate':
            return {...state, fileDate: action.payload};
        case 'filePlace':
            return {...state, filePlace: action.payload};
        case 'comment':
            return {...state, comment: action.payload};
        case 'loader':
            return {...state, loader: action.payload};
        case 'currentId':
            return {...state, currentId: action.payload};
        case 'currentEl':
            return {...state, currentEl: action.payload};
        case 'editMode':
            return {...state, editMode: action.payload};
        default:
            return state;    }
};
const ExamRegistration = () => {
    const sspGrade = JSON.parse(localStorage.getItem('sspGrade'));

    const [openExamRegistrationForm, setOpenExamRegistrationForm] = useState(false);
    const [showExamForm, setShowExamForm] = useState(false);
    const [showMarkForm, setShowMarkForm] = useState(false);


    const [applicationDateErr, setApplicationDateErr] = useState(false);
    const [fileNumberErr, setFileNumberErr] = useState(false);
    const [fileDateErr, setFileDateErr] = useState(false);
    const [filePlaceErr, setFilePlaceErr] = useState(false);
    const [commentErr, setCommentErr] = useState(false);

    const {activeRole} = useContext(UserRoleContext);

    const [state, dispatch] = useReducer(reducer,{
        examRegistrations:[],
        notPassedCourses:[],
        loader: false,
        editForm: false,
        comment:'',
        fileNumber: '',
        fileDate: null,
        filePlace: '',
        applicationDate: null,
        currentId: null,
        editMode: false

    });

    function setCurrentId(value){
        dispatch({type: 'currentId', payload: value?.id});
        dispatch({type: 'currentEl', payload: value});
    }

    useEffect(()=>{
        getExamRegistrations()
        getNotPassedCourses()
    },[]);

    let toggleOpenExamRegistrationForm = () =>{
        setOpenExamRegistrationForm(!openExamRegistrationForm);
    };

    function resetFormValues(){
        dispatch({type: 'applicationDate', payload: null});
        dispatch({type: 'fileNumber', payload: null});
        dispatch({type: 'fileDate', payload: null});
        dispatch({type: 'filePlace', payload: ''});
        dispatch({type: 'editMode', payload: false});
    }

    function getExamRegistrations(){
        dispatch({type: 'loader', payload: true});
        axios.get(`exam-registration/by-student-study-program-grade/${sspGrade.id}`,).then(
            (res) => {
                let examRegistrations = res.data;
                dispatch({type: 'examRegistrations', payload: examRegistrations});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            dispatch({type: 'loader', payload: false});
            console.log('err', err)
        });

    }

    function getNotPassedCourses(){
        dispatch({type: 'loader', payload: true});
        axios.get(`get course-study-program/not-passed/by-student-study-program-and-grade/{studentStudyProgramId}/${sspGrade.studentStudyProgram.id}/${sspGrade.id}`,).then(
            (res) => {
                let notPassedCourses = res.data;
                dispatch({type: 'notPassedCourses', payload: notPassedCourses});
                dispatch({type: 'loader', payload: false});
            }).catch((err) => {
            dispatch({type: 'loader', payload: false});
            console.log('err', err)
        });

    }

    function addExamRegistration(){
        if (!state.applicationDate) { setApplicationDateErr(true); } else { setApplicationDateErr(false); }
        if (!state.fileNumber) { setFileNumberErr(true); } else { setFileNumberErr(false); }
        if (!state.fileDate) { setFileDateErr(true); } else { setFileDateErr(false); }
        if (!state.filePlace) { setFilePlaceErr(true); } else { setFilePlaceErr(false); }
        if (!state.comment) { setCommentErr(true); } else { setCommentErr(false); }
        if (!state.applicationDate || !state.fileNumber || !state.fileDate || !state.filePlace )
        {
            toast("Niste unijeli sve podatke!");
        }else{
            dispatch({type: 'loader', payload: true});
            let obj = {
                studentStudyProgramGrade: {id: sspGrade.id},
                applicationDate: state.applicationDate,
                fileNumber: state.fileNumber,
                fileDate: state.fileDate,
                filePlace: state.filePlace,
                comment: state.comment,

            };
            axios.post('exam-registration/save', {...obj}).then(
                (res) => {
                    dispatch({type: 'loader', payload: false});
                    toast('Prijava za ispit je uspješno kreirana!');
                    toggleOpenExamRegistrationForm();
                    resetFormValues();
                    getExamRegistrations();
                }).catch((err) => {
                toast('Došlo je do greške!')
                dispatch({type: 'loader', payload: false});
                console.log('err', err)
            });
        }


    }

    function editExamRegistration(){
        if (!state.applicationDate) { setApplicationDateErr(true); } else { setApplicationDateErr(false); }
        if (!state.fileNumber) { setFileNumberErr(true); } else { setFileNumberErr(false); }
        if (!state.fileDate) { setFileDateErr(true); } else { setFileDateErr(false); }
        if (!state.filePlace) { setFilePlaceErr(true); } else { setFilePlaceErr(false); }
        if (!state.comment) { setCommentErr(true); } else { setCommentErr(false); }
        if (!state.applicationDate || !state.fileNumber || !state.fileDate || !state.filePlace )
        {
            toast("Niste unijeli sve podatke!");
        }else{
            dispatch({type: 'loader', payload: true});
            let obj = {
                id: state.currentEl.id,
                optLock: state.currentEl.optLock,
                studentStudyProgramGrade: state.currentEl.studentStudyProgramGrade,
                applicationDate: state.applicationDate,
                fileNumber: state.fileNumber,
                fileDate: state.fileDate,
                filePlace: state.filePlace,
                comment: state.comment,

            };
            axios.put('exam-registration/edit', {...obj}).then(
                (res) => {
                    dispatch({type: 'loader', payload: false});
                    toast('Uspješno ste izmijenili podatke.');
                    toggleOpenExamRegistrationForm();
                    resetFormValues();
                    getExamRegistrations();
                }).catch((err) => {
                toast('Došlo je do greške!')
                dispatch({type: 'loader', payload: false});
                console.log('err', err)
            });
        }


    }

    function downloadBlankExamRegistrationForm(){
        axios({
            method: 'get',
            url: 'document/exam-registration-form/blank',
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function downloadExamRegistrationForm(id){
        //prijava i zapisnik ali popunjena samo prijava
        axios({
            method: 'post',
            url: `document/exam-registration-form/${sspGrade.studentStudyProgram.id}/${id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greškeeee!');
            });

    }

    function downloadFullExamRegistrationForm(id){
        //prijava i zapisnik ali popunjena samo prijava
        axios({
            method: 'post',
            url: `document/exam-registration-form/full/${sspGrade.studentStudyProgram.id}/${id}`,
            responseType: 'blob',
            // data: { ...obj }
        })
            .then(res => {
                if (res.data.size === 0) {
                    return;
                }
                const fileName = extractFileNameFromHeaders(res.headers);
                download(res.data, fileName);
                return res;
            })
            .catch(err => {
                toast('Došlo je do greške!');
            });

    }

    function handleInputChange(event, { name, value }) {
        dispatch({type: name, payload: value});
    }

    function handleDateChange(name, date) {
        dispatch({type: name, payload: date});
    }

    let toggleExamForm = () =>{
        setShowMarkForm(false);
        setShowExamForm(!showExamForm)
    };

    let toggleMarkForm = () =>{
        setShowExamForm(false);
        setShowMarkForm(!showMarkForm)
    };

    function deleteExamRegistration(id){
        setCurrentId(id);
        axios.delete(`exam-registration/delete/${id}`).then(
            (res) => {
                getExamRegistrations();
                toast('Uspješno ste izbrisali prijavu!');
                setCurrentId(null);
            }).catch((err) => {
            if (err.response.data.message === 'Could not delete exam registration because it has at least one registered exam.') {
                toast('Prije brisanja potrebno je prvo ukloniti ispite sa prijave.');
            } else {
                toast('Došlo je do greške! Brisanje prijave nije uspjelo.');
            }
            setCurrentId(null);
            console.log('err', err)
        });
    }

    function activateEditMode(el){

        dispatch({type: 'applicationDate', payload: new Date(el.applicationDate)});
        dispatch({type: 'fileNumber', payload: el.fileNumber});
        dispatch({type: 'fileDate', payload: new Date(el.fileDate)});
        dispatch({type: 'filePlace', payload: el.filePlace});
        dispatch({type: 'editMode', payload: true});
        dispatch({type: 'currentEl', payload: el});
        toggleOpenExamRegistrationForm();
    }

    return (
        <div>
            <Segment.Group >
                <Segment className={'customPadding'} color={"teal"} clearing>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item >
                            <Header as={'h3'} floated={"left"}> Prijava ispita i unos ocjena za {sspGrade.grade.name} razred

                                <Header.Subheader>
                                    <i>Polaznik/ica: {sspGrade.studentStudyProgram.student.person.firstName + ' ' + sspGrade.studentStudyProgram.student.person.lastName}</i>
                                </Header.Subheader>
                            </Header>

                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                                <span>
                                     <Button icon labelPosition='left' size="mini" basic color="black" onClick={downloadBlankExamRegistrationForm} >
                                                                <Icon name='download' color={"teal"} />
                                                                Preuzmi prazan zapisnik o polaganju ispita
                                                            </Button>
                                    <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                        <Icon name='arrow left'/>
                                    </Button>
                                    <Icon/>
                                </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>


                {/*promijeniti flag, dodati neki drugi koji otvara formu exams*/}
                {showExamForm &&
                    <Segment>
                        <Exams
                            toggleExamForm={toggleExamForm}
                            registration={state.currentEl}
                            registrationId={state.currentId}

                        />
                    </Segment>
                }

                {showMarkForm &&
                <Segment clearing>
                    <Marks
                        toggleMarkForm={toggleMarkForm}
                        registration={state.currentEl}
                        registrationId={state.currentId}
                    />
                </Segment>
                }

                {<Segment>
                            {state.loader ?
                                <div style={{ paddingTop: '100px', paddingBottom: '100px' }}><Loader active inline="centered" /></div>
                                : <Table celled size={"small"}>

                                        <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell colSpan={openExamRegistrationForm ? 7: 6}>
                                                <Header as={'h5'} floated={"left"}>
                                                    Prijave
                                                    <span>
                                                    <Icon/></span>
                                                    <Header.Subheader>
                                                        <i>Polaznik može imati više prijava, ovisno koliko puta pristupa ispitima</i>
                                                    </Header.Subheader>
                                                </Header>
                                                </Table.HeaderCell>
                                            {!openExamRegistrationForm &&<Table.HeaderCell textAlign="center" >

                                                <Popup size={"small"}
                                                       content='Dodaj prijavu'
                                                       trigger={
                                                           <Button
                                                               icon
                                                               size={"tiny"}
                                                               basic
                                                               compact
                                                               onClick={()=>{resetFormValues(); toggleOpenExamRegistrationForm()}}
                                                           >
                                                               <Icon name='add' color={"teal"} />
                                                           </Button>
                                                       }
                                                />

                                            </Table.HeaderCell>
                                            }
                                        </Table.Row>


                                        <Table.Row >
                                            <Table.HeaderCell width={4} >  Datum prijave </Table.HeaderCell>
                                            <Table.HeaderCell > Djelovodni broj</Table.HeaderCell>
                                            <Table.HeaderCell width={4}>Djelovodni datum</Table.HeaderCell>
                                            <Table.HeaderCell>  Djelovodno mjesto </Table.HeaderCell>
                                            {/*<Table.Cell > <Table.HeaderCell> Napomena </Table.HeaderCell></Table.Cell>*/}
                                            <Table.HeaderCell  />
                                            <Table.HeaderCell  />
                                            <Table.HeaderCell width={2} />
                                        </Table.Row>
                                        </Table.Header>
                                    <Table.Body>
                                        {openExamRegistrationForm && <Table.Row>

                                            <Table.Cell>
                                                <Form size={'tiny'}>
                                                    <Form.Field
                                                        required
                                                        error={applicationDateErr}
                                                        control={DateTimePicker}
                                                        format="D. M. YYYY."
                                                        placeholder="Unesite datum"
                                                        name="applicationDate"
                                                        value={state.applicationDate}
                                                        time={false}
                                                        onChange={(e) => handleDateChange('applicationDate', e)}
                                                        style={{ borderWidth: '0px' }}
                                                    />
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell >
                                                <Form size={'tiny'}><Form.Field
                                                    required
                                                    error={fileNumberErr}
                                                    control={Input}
                                                    placeholder="Unesite broj"
                                                    value={state.fileNumber}
                                                    name="fileNumber"
                                                    onChange={handleInputChange}
                                                    // error={this.state.jmbgErr}
                                                />
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Form size={'tiny'}>

                                                    <Form.Field
                                                        required
                                                        error={fileDateErr}
                                                        control={DateTimePicker}
                                                        format="D. M. YYYY."
                                                        placeholder="Unesite datum"
                                                        name="fileDate"
                                                        value={state.fileDate}
                                                        time={false}
                                                        onChange={(e) => handleDateChange('fileDate', e)}
                                                        style={{ borderWidth: '0px' }}
                                                    />


                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell >
                                                <Form size={'tiny'}>
                                                    <Form.Field
                                                        required
                                                        error={filePlaceErr}
                                                        control={Input}
                                                        placeholder="Unesite mjesto"
                                                        value={state.filePlace}
                                                        name="filePlace"
                                                        onChange={handleInputChange}
                                                    />
                                                </Form>
                                            </Table.Cell>
                                            <Table.Cell textAlign="center" />
                                            <Table.Cell textAlign="center" />
                                            <Table.Cell textAlign="center" >
                                                <Form size={'tiny'} >
                                                    <Form.Field>
                                                        <Popup size={"small"}
                                                               content={state.editMode?'Sačuvaj':'Dodaj'}
                                                               trigger={
                                                                   <Button
                                                                       icon
                                                                       size={"tiny"}
                                                                       basic
                                                                       compact
                                                                       onClick={state.editMode?editExamRegistration:addExamRegistration}
                                                                   >
                                                                       <Icon name='check' color={"teal"} />
                                                                   </Button>
                                                               }
                                                        />
                                                        <Popup size={"small"}
                                                               content='Odustani'
                                                               trigger={
                                                                   <Button
                                                                       icon
                                                                       size={"tiny"}
                                                                       basic
                                                                       compact
                                                                       onClick={()=>{resetFormValues(); toggleOpenExamRegistrationForm();}}
                                                                   >
                                                                       <Icon name='close' color={"black"} />
                                                                   </Button>
                                                               }
                                                        />


                                                        {/*<Button size={"tiny"} basic color={'teal'} circular as={Icon} icon={'check'} onClick={addRecognizedGrade}/>*/}
                                                        {/*<Button size={"tiny"} basic color={'black'} circular as={Icon} icon={'close'} onClick={toggleOpenGradeForm}/>*/}
                                                    </Form.Field>
                                                </Form>
                                            </Table.Cell>
                                        </Table.Row>}
                                        {state.examRegistrations.length !== 0 ? state.examRegistrations.map((el, index)=>(
                                            <Table.Row >
                                                <Table.Cell > {el.applicationDate && moment(el.applicationDate).format('D. M. YYYY.') }</Table.Cell>
                                                <Table.Cell> {el.fileNumber}</Table.Cell>
                                                <Table.Cell> {el.fileDate && moment(el.fileDate).format('D. M. YYYY.') } </Table.Cell>
                                                <Table.Cell> {el.filePlace} </Table.Cell>
                                                <Table.Cell verticalAlign="middle" textAlign="center" >

                                                    <Popup size={"small"}
                                                           content='Prijavi ispite'
                                                           trigger={
                                                               <Button
                                                                   disabled={showExamForm}
                                                                   color={"teal"}
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={()=>{toggleExamForm(); setCurrentId(el)}}
                                                               >
                                                                   Prijava ispita
                                                               </Button>
                                                           }
                                                    />
                                                </Table.Cell>
                                                <Table.Cell verticalAlign="middle" textAlign="center">

                                                    <Popup size={"small"}
                                                           content='Unesi ocjene'
                                                           trigger={
                                                               <Button
                                                                   disabled={showMarkForm}
                                                                   color={"black"}
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={() => {
                                                                       toggleMarkForm();
                                                                       setCurrentId(el)
                                                                   }}
                                                               >
                                                                   Unos ocjena
                                                               </Button>
                                                           }
                                                    />


                                                </Table.Cell>
                                                <Table.Cell verticalAlign="middle" textAlign="center" >
                                                    <Popup size={"small"}
                                                           content='Izmijeni podatke'
                                                           trigger={
                                                               <Button
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={()=>{activateEditMode(el)}}
                                                               >
                                                                   <Icon name='pencil' color={"teal"} />
                                                               </Button>
                                                           }
                                                    />
                                                    {/*<Popup size={"small"}*/}
                                                    {/*       content='Prikaži ispite'*/}
                                                    {/*       trigger={*/}
                                                    {/*           <Button*/}
                                                    {/*               icon*/}
                                                    {/*               size={"tiny"}*/}
                                                    {/*               basic*/}
                                                    {/*               compact*/}
                                                    {/*               onClick={()=>{toggleExamForm(); setCurrentId(el)}}*/}
                                                    {/*           >*/}
                                                    {/*               <Icon name='tasks' color={"blue"} />*/}
                                                    {/*           </Button>*/}
                                                    {/*       }*/}
                                                    {/*/>*/}
                                                    {/*<Popup size={"small"}*/}
                                                    {/*       content='Ocjene'*/}
                                                    {/*       trigger={*/}
                                                    {/*           <Button*/}
                                                    {/*               icon*/}
                                                    {/*               size={"tiny"}*/}
                                                    {/*               basic*/}
                                                    {/*               compact*/}
                                                    {/*               onClick={()=>{toggleMarkForm(); setCurrentId(el)}}*/}
                                                    {/*           >*/}
                                                    {/*               <Icon name='checkmark' color={"teal"} />*/}
                                                    {/*           </Button>*/}
                                                    {/*       }*/}
                                                    {/*/>*/}
                                                    <Popup size={"small"}
                                                           content='Preuzmi prijavu i zapisnik'
                                                           trigger={
                                                               <Button
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={()=>downloadFullExamRegistrationForm(el.id)}
                                                                   disabled={activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY"}
                                                               >
                                                                   <Icon name='download' color={"green"} />
                                                               </Button>
                                                           }
                                                    />
                                                    <Popup size={"small"}
                                                           content='Preuzmi praznu prijavu i zapisnik'
                                                           trigger={
                                                               <Button
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={()=>downloadExamRegistrationForm(el.id)}
                                                                   disabled={activeRole === "ROLE_ADMIN" || activeRole === "ROLE_MINISTRY"}
                                                               >
                                                                   <Icon name='download' color={"teal"} />
                                                               </Button>
                                                           }
                                                    />
                                                    <Popup size={"small"}
                                                           content='Obriši prijavu'
                                                           trigger={
                                                               <Button
                                                                   icon
                                                                   size={"tiny"}
                                                                   basic
                                                                   compact
                                                                   onClick={()=>{deleteExamRegistration(el.id)}}
                                                               >
                                                                   <Icon name='trash alternate outline' color={"red"} />
                                                               </Button>
                                                           }
                                                    />

                                                </Table.Cell>
                                            </Table.Row>)):
                                            <Table.Row><Table.Cell colSpan={7}> Polaznik trenutno nema prijava u odabranom razredu.</Table.Cell></Table.Row>
                                        }

                                    </Table.Body>
                                </Table>



                            }



                </Segment>}
            <ToastContainer/>
            </Segment.Group>
        </div>
    );
};

export default ExamRegistration;
