import React, {useContext, useEffect, useState} from 'react';
import UserService from "../../service/UserService";
import AdminHome from "./AdminHome";
import OrganizerHome from "./OrganizerHome";
import {UserRoleContext} from "../../Main";
import MinistryHome from "./MinistryHome";

const Home = () => {
    const user = UserService.getUsername();
    const {activeRole, getActiveRole} = useContext(UserRoleContext);
    // const [activeRole, setActiveRole] = useState();
    // useEffect(()=>{
    //     setActiveRole(window.localStorage.getItem('activeRole'));
    // },[]);
    return (
        <div>
            <h2 className='textAlignCenter' >ISKOOM
                <h4 className='textAlignCenter' ><i>Informacioni sistem za kontrolu obrazovanja odraslih i monitoring</i> </h4>
            </h2>


            {activeRole === 'ROLE_ADMIN' && <AdminHome/>}
            {activeRole === 'ROLE_ORGANIZER' && <OrganizerHome/>}
            {activeRole === 'ROLE_MINISTRY' && <MinistryHome/>}

        </div>
    );
};

export default Home;
