import React, {useContext, useEffect, useLayoutEffect, useReducer, useState} from 'react';
import Segment from "semantic-ui-react/dist/commonjs/elements/Segment";
import {Form, GridColumn, GridRow, Header, Menu, SegmentGroup} from "semantic-ui-react";
import {useLocation} from "react-router-dom";
import OrganizerForm from "./OrganizerForm";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import {GoogleMap, LoadScript} from '@react-google-maps/api';
import GeneralInfo from "./OrganizerDetails/GeneralInfo";
import AuthPerson from "./OrganizerDetails/AuthPerson";
import axios from "axios";
import {ToastContainer} from "react-toastify";
import AuthPersonForm from "./OrganizerDetails/AuthPersonForm";
import {UserRoleContext} from "../../Main";
import Popup from "semantic-ui-react/dist/commonjs/modules/Popup";
import Table from "semantic-ui-react/dist/commonjs/collections/Table";
import PlaceForm from "../Utilities/PlaceForm";
import PlaceInfo from "./OrganizerDetails/PlaceInfo";
import OrganizerPlaceForm from "./OrganizerPlaceForm";
import OrganizerEditForm from "./OrganizerEditForm";
import {DateTimePicker} from "react-widgets";
import moment from "moment";
const reducer = (state, action)=>{
    switch (action.type) {
        case 'entities':
            return {...state, entities: action.payload };
        case 'cantons':
            return {...state, cantons: action.payload };
        case 'municipalities':
            return {...state, municipalities: action.payload };
        case 'organizer':
            return {...state, organizer: action.payload, loader:  false };
        case 'authPersons':
            return {...state, authPersons: action.payload};
        default:
            return state;
    }
};

const OrganizerDetails = (props) => {
    const {activeRoleObj, activeRole} = useContext(UserRoleContext);
    const location = useLocation();
    let name = location.state && location.state.name ? location.state.name:( activeRoleObj && activeRoleObj.userOrganizer) ? activeRoleObj.userOrganizer.organizer.name: '';
    let id = location.state && location.state.id ? location.state.id: (activeRoleObj && activeRoleObj.userOrganizer)? activeRoleObj.userOrganizer.organizer.id: null;




    const [showEditForm, setShowEditForm] = useState(false);
    const [showEditPlaceForm, setShowEditPlaceForm] = useState(false);
    const [showAuthForm, setShowAuthForm] = useState(false);
    const [showAddAuthForm, setShowAddAuthForm] = useState(false);
    const [state, dispatch] = useReducer(reducer,{ organizer: undefined, authPersons:[], loader: true} );

    const containerStyle = {
        width: '100%',
        height: '200px'
    };

    const center = {
        lat: 42.745,
        lng: 18.523
    };

    useEffect(() => {
        if (id) getOrganizerById();
        if(activeRole === "ROLE_ORGANIZER") {
            getAuthPersonsForOrganizer()
        }
        else{
            getAuthPersons();
        }
        getMunicipality();

    },[id]);

    let toggleEditForm = () =>{
        setShowEditForm(!showEditForm)
    };

    let toggleAuthForm = () =>{
        setShowAuthForm(!showAuthForm)
    };

    let toggleAddAuthForm = () =>{
        setShowAddAuthForm(!showAddAuthForm)
    };

    let toggleEditPlaceForm = () =>{
        setShowEditPlaceForm(!showEditPlaceForm)
    };

    function getOrganizerById() {
        if(id){
            axios.get(`/organizer/${id}`,).then(
                (res) => {
                    let organizer = res.data;
                    dispatch({type: 'organizer', payload: organizer});
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function getMunicipality() {
        axios.get('/municipality/all',).then(
            (res)=>{
                let municipalityOptions = res.data.map((el)=>({key: el.key, text: el.name, value: el}));
                dispatch({type:'municipalities', payload: municipalityOptions});
                //dispatch({type:'filteredMunicipalities', payload: municipalityOptions});

            }).catch((err)=>{
            console.log('err', err)
        });

    }

    function getAuthPersons() {
        if(id) {
            axios.get(`/organizers-authorized-person/by-organizer-id/${id}`,).then(
                (res) => {
                    let authPersons = res.data;
                    dispatch({type: 'authPersons', payload: authPersons});
                }).catch((err) => {
                console.log('err', err)
            });
        }
    }

    function getAuthPersonsForOrganizer() {
            axios.get('/organizers-authorized-person/by-organizer',).then(
                (res) => {
                    let authPersons = res.data;
                    dispatch({type: 'authPersons', payload: authPersons});
                }).catch((err) => {
                console.log('err', err)
            });
    }


    return (

        <div>
            <Segment.Group>
                <Segment className={'customPadding'} clearing color={"teal"}>
                    <Menu  secondary size={"tiny"} compact stackable floated={"right"} fluid>
                        <Menu.Item ><Header as={'h3'} floated={"left"}>
                            {name}
                            <span>
                            <Icon/></span>
                            <Header.Subheader>
                                <i>Naziv organizatora</i>
                            </Header.Subheader>
                        </Header>
                        </Menu.Item>
                        <Menu.Menu position={'right'}>
                            <Menu.Item>
                            <span>
                                <Button floated={"right"} icon size={"mini"} basic onClick={() => window.history.go(-1)}>
                                    <Icon name='arrow left'/>
                                </Button>
                                <Icon/>
                            </span>
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                </Segment>
                <Segment>
                    {/*send add or edit as props*/}


                        <Grid columns={2}>
                            <GridRow>
                                <GridColumn>
                                    <SegmentGroup>
                                        <Segment >
                                            <Header as={'h4'}>
                                                <span><Icon  name='address card outline' color={"black"} /></span>
                                                Osnovni podaci
                                                {!showEditForm && <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditForm} disabled={activeRole === 'ROLE_ORGANIZER'}>
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                    {/*placeholder*/}
                                                    <Icon/></span>}
                                            </Header>
                                        </Segment>
                                        {showEditForm && <Segment><OrganizerEditForm edit toggleEditForm={toggleEditForm} getOrganizer={getOrganizerById} organizer={state.organizer} /></Segment>}
                                        <Segment>
                                            <GeneralInfo organizer={state.organizer}/>
                                        </Segment>
                                    </SegmentGroup>
                                <SegmentGroup>
                                    <Segment >
                                        <Header as={'h4'}>
                                            <span><Icon  name='address card outline' color={"black"} /></span>
                                            Adresa
                                            {!showEditForm && <span>
                                                       <Popup size={"small"} content={'Izmijeni podatke'} trigger={
                                                           <Button floated={"right"} icon size={"mini"} basic onClick={toggleEditPlaceForm} disabled={activeRole === 'ROLE_ORGANIZER'}>
                                                               <Icon name='pencil alternate' color={"teal"}/>
                                                           </Button>} />

                                                {/*placeholder*/}
                                                <Icon/></span>}
                                        </Header>
                                    </Segment>
                                    {showEditPlaceForm && <Segment>
                                        <OrganizerPlaceForm edit toggleEditForm={toggleEditPlaceForm} getOrganizer={getOrganizerById} organizer={state.organizer} municipalities={state.municipalities}/>
                                    </Segment>}
                                    <Segment>
                                        <PlaceInfo residence={state.organizer? state.organizer.residence : null}/>
                                    </Segment>
                                </SegmentGroup>
                            </GridColumn>
                                <GridColumn>
                                    <SegmentGroup>
                                        <Segment >
                                            <Header as={'h4'}>
                                                <span><Icon  name='user' color={"black"} /></span>
                                                Trenutno ovlašteno lice
                                                {!showEditForm && <span>
                                                    <Button floated={"right"} icon size={"mini"} basic onClick={toggleAuthForm} disabled={state.authPersons.length === 0}>
                                                        <Icon name='pencil alternate' color={"teal"}/>
                                                    </Button>
                                                    {(state.authPersons && state.authPersons[0] && state.authPersons[0].endDate === null ) ?<Popup size={"small"} content='Nije moguće dodati novo ovlašteno lice dok se prethodnom ne unese datum kraja.' trigger={
                                                            <Button floated={"right"} icon size={"mini"} basic>
                                                                <Icon link basic name="info"/>
                                                            </Button>}
                                                    /> :
                                                        <Button floated={"right"} icon size={"mini"} basic onClick={toggleAddAuthForm}>
                                                            <Icon name='add' color={"teal"}/>
                                                        </Button>}
                                                    {/*<Button floated={"right"} icon size={"mini"} basic onClick={toggleAddAuthForm} disabled={state.authPersons && state.authPersons[0] && state.authPersons[0].endDate === null}>*/}
                                                    {/*    <Icon name='add' color={"teal"}/>*/}
                                                    {/*</Button>*/}
                                                    <Icon/></span>}
                                            </Header>
                                        </Segment>
                                       { showAuthForm && <Segment>
                                            <AuthPersonForm edit toggleEditForm={toggleAuthForm}  getAuthPersons={getAuthPersons} getAuthPersonsForOrganizer={getAuthPersonsForOrganizer} organizer={state.organizer} authPerson={state.authPersons[0]}/>
                                        </Segment>}
                                        { showAddAuthForm && <Segment>
                                            <AuthPersonForm add toggleEditForm={toggleAddAuthForm}  getAuthPersons={getAuthPersons} getAuthPersonsForOrganizer={getAuthPersonsForOrganizer} organizer={state.organizer}/>
                                        </Segment>}
                                        <Segment>
                                           <AuthPerson authPerson={state.authPersons[0]}  />
                                        </Segment>
                                    </SegmentGroup>

                                    <SegmentGroup>
                                        <Segment >
                                            <Header as={'h4'}>
                                                <span><Icon  name='list' color={"black"} /></span>
                                                Prethodno ovlaštena lica
                                            </Header>
                                        </Segment>
                                        <Segment>
                                            {state.authPersons.length!=0 ?
                                            <Table celled size={'small'}>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>R. br.</Table.HeaderCell>
                                                        <Table.HeaderCell>Ime i prezime ovlaštenog lica</Table.HeaderCell>
                                                        <Table.HeaderCell>Datum imenovanja</Table.HeaderCell>
                                                        <Table.HeaderCell>Broj odluke</Table.HeaderCell>
                                                        <Table.HeaderCell>Datum početka</Table.HeaderCell>
                                                        <Table.HeaderCell>Datum kraja</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {
                                                        state.authPersons.map((el, index) => (
                                                            <Table.Row key={el.id}>
                                                                <Table.Cell>{index+1}.</Table.Cell>
                                                                <Table.Cell>{el.firstName} {el.lastName}</Table.Cell>
                                                                <Table.Cell>{moment(el.documentDate).format('D. M. YYYY.')}</Table.Cell>
                                                                <Table.Cell>{el.documentNumber}</Table.Cell>
                                                                <Table.Cell>{moment(el.startDate).format('D. M. YYYY.')}</Table.Cell>
                                                                <Table.Cell>{el.endDate ? moment(el.endDate).format('D. M. YYYY.'): ''}</Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                    }
                                                </Table.Body>
                                            </Table> : 'Nema podataka o prethodno ovlaštenim licima.'}
                                        </Segment>
                                    </SegmentGroup>

                                    {/*<SegmentGroup>*/}
                                    {/*    <Segment ><Header as={'h4'}>*/}
                                    {/*        <span><Icon name='location arrow' color={"black"} /> </span> Mapa </Header></Segment>*/}
                                    {/*    <Segment>*/}
                                    {/*        <div  className={'map'}>*/}
                                    {/*            <LoadScript*/}
                                    {/*                googleMapsApiKey="AIzaSyDZZ0GW44zdF8GOJ44evRXrqParT13BDsY"*/}
                                    {/*            >*/}
                                    {/*                <GoogleMap*/}
                                    {/*                    mapContainerStyle={containerStyle}*/}
                                    {/*                    center={center}*/}
                                    {/*                    zoom={10}*/}
                                    {/*                >*/}
                                    {/*                </GoogleMap>*/}
                                    {/*            </LoadScript>*/}
                                    {/*        </div>*/}
                                    {/*    </Segment>*/}
                                    {/*</SegmentGroup>*/}
                                </GridColumn>
                            </GridRow>

                        </Grid>


                </Segment>
            </Segment.Group>
            <ToastContainer/>
        </div>
    );
};

export default OrganizerDetails;
